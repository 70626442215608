import React from "react";
import { Link } from "react-router-dom";
import { asset7, logo } from "../../assests/img/index";

const Signup = () => {
  return (
    <div style={{ height: "100vh" }}>
      <Link to="/">
        <img
          style={{ zIndex: 1 }}
          src={logo}
          alt="Image"
          className="signin_page"
          // width="140"
          height="70"
        />
      </Link>
      <div className="sign_up w-90 my-5">
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-5 px-3 text-center m-auto">
            <img src={asset7} alt="" />
          </div>
          <div className="col-md-6 px-3 m-auto">
            <div className="si_gn si_tx">
              {/* <div style={{ margin: "0 auto", width: "100px", height: "65px" }}>
                <img src={logo} />
              </div> */}
              <h3>Sign up</h3>
              <form action="">
                <div className="na_me">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                  />
                  <label htmlFor="name">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                  />
                  <label htmlFor="name">Contact</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact"
                    placeholder="Contact"
                  />
                  <label htmlFor="name">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="te_co">
                  <label>
                    <input type="checkbox" checked="checked" name="terms" />I
                    have read, and I agree to the <span>Terms of Service</span>{" "}
                    and <span>Privacy Policy</span>
                  </label>
                  <div className="lo_sub text-center">
                    <button className="btn btn-dark" type="submit">
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
