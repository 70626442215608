import React from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import Enquiry from "./Enquiry";
import Iiotbanner from "./iiotbanner";
import Iiotservices from "./iiotservices";
import Iiotwork from "./iiotwork";

const Iiot = () => {
  return (
    <div>
      <Navbar />
      <Iiotbanner />
      <Iiotwork />
      <Iiotservices />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default Iiot;
