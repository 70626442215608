import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../Components/assests/img/logo.png";
import ApplicationService from "../Services/Service/service";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { ProductsContext } from "./Routes/Routes";

const Navbar = () => {
  const { productList } = useContext(ProductsContext);

  // const [data, setData] = useState(dataNav);

  // const [name, setName] = useState();
  // const getNAme = (id) => {
  //   setName(id.name);
  // };

  // console.log(productList);

  // useEffect(() => {
  //   ApplicationService.productList()
  //     .then((res) => {
  //       setData(res.data.products);
  //       // console.log(name);
  //     })
  //     .catch(function (error) {
  //       ErrorHandler(error);
  //     });
  // }, []);

  return (
    <div>
      <div className="nav-bar">
        <nav className="navbar navbar-expand-md navbar-light px-3 nav_me">
          <Link to="/">
            <a href="">
              <img src={logo} alt="logo" />
            </a>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end min-nav"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav nav_a">
              <li className="nav-item dropdown ">
                <a
                  className="nav-link text-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Products
                </a>
                <ul className="dropdown-menu dr_li">
                  {productList?.map((item, i) => {
                    return (
                      <NavLink
                        key={i}
                        activeClassName="is-active"
                        to={{
                          pathname: "/products",
                          state: item.slug_name,
                        }}
                      >
                        <li>
                          <a className="dropdown-item " href="">
                            {item.name}
                          </a>
                        </li>
                      </NavLink>
                    );
                  })}
                  {/* <li>
                   
                    <a className="dropdown-item " href="">
                      crm
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown ">
                <a
                  className="nav-link text-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Services
                </a>
                <ul className="dropdown-menu dr_li">
                  <li>
                    <Link to="/iot">
                      <a className="dropdown-item " href="">
                        Industrial Internet of Things
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/vrar">
                      <a className="dropdown-item " href="">
                        VR / AR Development
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/app">
                      <a className="dropdown-item " href="">
                        App Development
                      </a>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/pricing"
                  activeClassName="is-active"
                  className="nav-link text-dark"
                >
                  Pricing
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  activeClassName="is-active"
                  className="nav-link text-dark"
                  to="/about"
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/career"
                  activeClassName="is-active"
                  className="nav-link text-dark"
                >
                  Career
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="is-active"
                  className="nav-link text-dark"
                  to="/support"
                >
                  Support
                </NavLink>
              </li>

              <li className="nav-item qu_o">
                <Link to="/signin">
                  <a
                    style={{ backgroundColor: "transparent" }}
                    className="nav-link btn bt_btn text-light border border-0"
                    aria-current="page"
                    href=""
                  >
                    Sign in
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;

{
  /* <button
  className="px-4 py-2 bg-slate-600 rounded text-[#fff]"
  onClick={() => setToDashboard(true)}
>
  Know More
</button>; */
}
