import React, { useEffect, useState } from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import PricingCard from "./PricingCard";
import ApplicationService from "../../../Services/Service/service";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";

const Pricing = () => {
  const [pricingData, setPricingData] = useState([]);

  const getData = () => {
    ApplicationService.pricingList()
      .then((res) => {
        setPricingData(res.data.pricings);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navbar />
      <PricingCard data={pricingData} />
      <Footer />
    </div>
  );
};

export default Pricing;
