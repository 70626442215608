import React from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import Enquiry from "../iiot/Enquiry";
import Vrbanner from "./vrbanner";
import Vrservices from "./Vrservices";
import Vrwork from "./vrwork";

const VrAr = () => {
  return (
    <div>
      <Navbar />
      <Vrbanner />
      <Vrwork />
      <Vrservices />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default VrAr;
