import React, { useEffect, useState } from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import Crmbanner from "./Crmbanner";
import Crmclient from "./Crmclient";
import Crmcontact from "./Crmcontact";
import Crmservices from "./Crmservices";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import ApplicationService from "../../../../Services/Service/service";
import ErrorHandler from "../../../../Services/Constant/ErrorHandler";
import CrmEnquiry from "./CrmEnquiry";

const Crm = () => {
  const history = useHistory();

  // const navigate = useNavigate();

  const [data, setData] = useState("");

  let location = useLocation();

  const getData = () => {
    // const result = axios.get('http://192.168.1.202/laravel-basic-crm/public/index.php/api/product/view/')
    const result = location.state;
    ApplicationService.productView(result)
      .then((res) => {
        setData(res.data.product);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    if (location.state) {
      getData();
    } else {
      history.goBack();
      // navigate(-1);
    }
  }, [location.state]);

  return (
    <div>
      <Navbar />

      {data && <Crmbanner data={data} />}
      {data?.amenities && <Crmservices data={data} />}

      {data?.contents && <Crmcontact data={data} />}
      {/* <Crmclient /> */}
      <CrmEnquiry />
      <Footer />
    </div>
  );
};

export default Crm;
