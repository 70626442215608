import React from "react";
import Footer from "../../../Footer";
import Navbar from "../../../Navbar";
import Enquiry from "../iiot/Enquiry";
import Appdbanner from "./appdbanner";
import Appdservices from "./appdservices";
import Appdwork from "./appdwork";

const AppDevelopment = () => {
  return (
    <div>
      <Navbar />
      <Appdbanner />
      <Appdwork />
      <Appdservices />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default AppDevelopment;
