// import React from "react";
import Banner from "../home/Banner";
import Whatcan from "../home/whatcan";
import Sone from "../home/Sone";
import Products from "../home/products";
import Services from "../home/Services";
import Enquiry from "../home/Enquiry";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import React, { useState, useEffect, useContext } from "react";
import ApplicationService from "../../../Services/Service/service";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";
import { ProductsContext } from "../../Routes/Routes";
const Home = () => {
  const { setProductList } = useContext(ProductsContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    ApplicationService.productList()
      .then((res) => {
        setData(res.data.products);
        // localStorage.setItem('products',res.data.products)
        setProductList(res.data.products);
        localStorage.setItem("products", JSON.stringify(res.data.products));
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);

  return (
    <div>
      <Navbar data={data} />
      <Banner />
      <Whatcan />
      <Sone />
      <Products />
      <Services />
      <Enquiry />
      <Footer />
    </div>
  );
};

export default Home;
