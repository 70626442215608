import React, { useState, useEffect } from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import Careerbanner from "./Careerbanner";
import Careerchoice from "./Careerchoice";
import ApplicationService from "../../../Services/Service/service";
import ErrorHandler from "../../../Services/Constant/ErrorHandler";

const Career = () => {
  const [careersData, setCareersData] = useState([]);

  const getData = () => {
    ApplicationService.careersList()
      .then((res) => {
        setCareersData(res.data.careers);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navbar />
      <Careerbanner />

      {careersData && <Careerchoice data={careersData} />}

      {/* <Careerchoice /> */}
      <Footer />
    </div>
  );
};

export default Career;
