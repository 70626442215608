// import logo from "./logo.svg";
import Navbar from "./Components/Navbar";
import Banner from "./Components/index";
import Footer from "./Components/Footer";
import Crm from "./Components/Crm";
import Iott from "./Components/iott";
import Vr from "./Components/Vr";
import Appde from "./Components/appde";
import Pricing from "./Components/pricing";
import About from "./Components/About";
import Career from "./Components/Career";
import Contactus from "./Components/Contactus";
import Signin from "./Components/Signin";
import Signup from "./Components/Signup";
import Routes from "./Components/Routes/Routes";
import Home from "./Components/Pages/home/Home";

import httpClient from "./Services/Constant/httpClient";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // spinning start to show
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // spinning hide
        setLoading(false);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <div>
      <div>
        {loading && (
          <div
            style={{
              position: "fixed",
              zIndex: 1500,
              top: "45%",
              left: "49%",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        <Routes />
      </div>
    </div>
  );
}

export default App;
