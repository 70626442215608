import React from "react";
import { signin, logo } from "../../assests/img/index";
import { Link } from "react-router-dom";

const Signin = () => {
  return (
    <div>
      <Link to="/">
        <img
          src={logo}
          alt="Image"
          className="signin_page"
          // width="140"
          height="70"
        />
      </Link>

      <div className="sub_ject w-90 my-5">
        <div className="d-flex flex-wrap align-items-center">
          <div className="col-md-6 px-3 m-auto">
            <img src={signin} alt="Image" />
          </div>
          <form action="">
            <div className="col-md-6 col-12 px-3">
              <div className="si_gn si_tx">
                <h3>Sign In</h3>
                <div className="us_er">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                  />
                </div>
                <div className="pas_wrd rounded">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                  />
                </div>
                <div className="rem_ber text-end">
                  <a href="forget.php">Forget password?</a>
                </div>
                <div className="lo_sub text-center">
                  <button className="btn btn-dark" type="submit">
                    Continue
                  </button>
                </div>
              </div>
              <div className="al_re" style={{ zIndex: 2 }}>
                New to Zercorp?
                <Link to="/signup">
                  <a href="">
                    <b>
                      <u>Sign-up</u>
                    </b>
                  </a>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
