import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import AboutBanner from "./AboutBanner";
import Aboutchoose from "./Aboutchoose";
import AboutCompany from "./AboutCompany";
import Abouttech from "./Abouttech";

const About = () => {
  return (
    <div>
      <Navbar />
      <AboutBanner />
      <AboutCompany />
      <Abouttech />
      <Aboutchoose />
      <Footer />
    </div>
  );
};

export default About;
