import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import SupportEnquiry from "./supportEnquiry";
import Supportinfo from "./Supportinfo";

const Support = () => {
  return (
    <div>
      <Navbar />
      <Supportinfo />
      <SupportEnquiry />
      <Footer />
    </div>
  );
};

export default Support;
